import { useEffect, useState } from "react";
import { GetMyBookings } from "../api";
import { Booking } from "../types";
import { Logo } from "../Svg";

import styles from "./home.module.css";

const isFuture = (booking: Booking): Boolean => {
  const starts = new Date(booking.startsAt);
  const now = new Date();

  const diff = now.getTime() - starts.getTime();
  const padding = 5 * (60 * 60 * 1000); // one hour in MS
  return diff < padding;
};

const Home: React.VFC = () => {
  const [upcomingBookings, setUpcomingBookings] = useState<Booking[] | null>(
    null
  );
  useEffect(() => {
    GetMyBookings().then((bookings) => {
      setUpcomingBookings(
        bookings.filter(
          (booking) => isFuture(booking) && booking.cancelled === false
        )
      );
    });
  }, []);

  return (
    <div className={styles.home}>
      <Logo className={styles.logo} />
      {upcomingBookings &&
        upcomingBookings.length > 0 &&
        upcomingBookings.map((booking) => (
          <div key={booking.id} className={styles.booking}>
            <a href={`/${booking.id}`}>
              Klicka här för att starta möte med{" "}
              {booking.bookedPerson.firstName} {booking.bookedPerson.lastName},{" "}
              {booking.bookedPerson.role}
            </a>
          </div>
        ))}
      {upcomingBookings && upcomingBookings.length === 0 && (
        <>
          <div className={styles.noBookings}>Du har inga bokade tider.</div>
        </>
      )}
    </div>
  );
};

export default Home;
