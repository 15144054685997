import {Logger, TurnAuthorization} from "../types";
import * as uuid from "uuid";
import React from "react";
import {diff} from "./util";

export type VideoFatalError = "websocket-closed";

interface parameters {
  meetingId: string;
  logger: Logger;
  turnAuthorization: TurnAuthorization;
  onPeerListUpdated: (peer: Peer[]) => void;
  onFatalErrorOccured: (error: VideoFatalError) => void;
  localStream: MediaStream;
  peerVideoRefs: React.MutableRefObject<HTMLVideoElement[]>;
}

export interface Peer {
  clientId: string;
  name: string;
  ready: boolean;
  joined: number;
  connectionHandler: ConnectionHandler | null;
  error: boolean;
}

export interface CallHandler {
  cleanup: () => void;
}

type send = (data: any) => void;
type createCallHandlerFunc = (options: parameters) => CallHandler;

export const createCallHandler: createCallHandlerFunc = ({
  meetingId,
  turnAuthorization,
  logger,
  peerVideoRefs,
  localStream,
  onPeerListUpdated,
  onFatalErrorOccured,
}): CallHandler => {
  let peers: Peer[] = [];
  let selfJoined = 0;
  let intentionallyClosed = false;
  const clientId = uuid.v4();

  const socket = new WebSocket(
    `${process.env.REACT_APP_API_WS_URL}/?meetingId=${meetingId}&clientId=${clientId}`
  );
  const send = (data: any) => {
    data["clientId"] = clientId;
    socket.send(JSON.stringify(data));
  };
  socket.onclose = (ev) => {
    if (!intentionallyClosed) {
      logger.error({message: "websocket closed", ev});
      onFatalErrorOccured("websocket-closed");
    }
  };
  socket.onerror = (ev) => {
    logger.error(ev);
    onFatalErrorOccured("websocket-closed");
  };

  const handleNewPeerList = (newPeers: Peer[]) => {
    const [add, drop] = diff(
      peers,
      newPeers,
      (a, b) => a.clientId === b.clientId
    );
    for (var i = peers.length; i >= 0; i--) {
      if (drop.includes(i)) {
        peers[i].connectionHandler?.close();
        peers.splice(i, 1);
      }
    }
    add.forEach((peer) => {
      peers.push({
        ...peer,
        connectionHandler: createConnectionHandler({
          selfJoined,
          peerJoined: peer.joined,
          turnAuthorization,
          send,
          peerVideoRefs,
          localStream,
          index: peers.length,
          clientId: peer.clientId,
          logger,
          onConnectionFailed: () => {
            peer.error = true;
            onPeerListUpdated(peers);
          },
        }),
      });
    });
    onPeerListUpdated(peers);
  };

  socket.onmessage = async (e) => {
    var msg = JSON.parse(e.data);
    switch (msg.type) {
      case "localDescription": {
        const {receiverId} = msg;
        if (receiverId !== clientId) return;
        const {clientId: senderClientId} = msg;
        peers
          .find((p) => p.clientId === senderClientId)
          ?.connectionHandler?.handleDescriptionMessage(msg.localDescription);
        break;
      }
      case "candidate": {
        const {clientId} = msg;
        peers
          .find((p) => p.clientId === clientId)
          ?.connectionHandler?.handleCandidateMessage(msg.candidate);
        break;
      }
      case "members": {
        selfJoined = msg.selfJoined;
        handleNewPeerList(msg.members);
        break;
      }
      case "pong": {
        break;
      }
      default: {
        logger.error("unhandled message type", msg);
        break;
      }
    }
  };
  socket.onopen = () => {};
  socket.onclose = () => {};
  const ping = () => {
    socket.send(
      JSON.stringify({
        type: "ping",
      })
    );
  };
  const interval = setInterval(ping, 10000);
  return {
    cleanup: () => {
      intentionallyClosed = true;
      socket.close();
      window.clearInterval(interval);
      peers.forEach((peer) => peer.connectionHandler?.close());
    },
  };
};

type ConnectionHandler = {
  close: () => void;
  handleDescriptionMessage: (
    description: RTCSessionDescriptionInit
  ) => Promise<void>;
  handleCandidateMessage: (candidate: RTCIceCandidateInit) => Promise<void>;
};

interface createConnectionHandlerOptions {
  selfJoined: number;
  peerJoined: number;
  turnAuthorization: TurnAuthorization;
  peerVideoRefs: React.MutableRefObject<HTMLVideoElement[]>;
  localStream: MediaStream;
  index: number;
  clientId: string;
  logger: Logger;
  onConnectionFailed: () => void;
  send: send;
}

type createConnectionHandlerFactory = (
  options: createConnectionHandlerOptions
) => ConnectionHandler;

const createConnectionHandler: createConnectionHandlerFactory = ({
  selfJoined,
  peerJoined,
  turnAuthorization,
  peerVideoRefs,
  localStream,
  index,
  clientId,
  logger,
  send,
  onConnectionFailed,
}): ConnectionHandler => {
  const rtcConnectionConfig: RTCConfiguration = {
    iceServers: [
      {
        urls: "turn:turn2.womni.se:5349",
        username: turnAuthorization.username,
        credential: turnAuthorization.password,
      },
      {
        urls: "stun:turn2.womni.se:5349",
      },
      {
        urls: "turn:turn.womni.se:5349",
        username: turnAuthorization.username,
        credential: turnAuthorization.password,
      },
      {
        urls: "stun:turn.womni.se:5349",
      },
    ],
  };
  const peerConnection = new RTCPeerConnection(rtcConnectionConfig);
  peerConnection.onicecandidate = async ({candidate}) => {
    send({
      type: "candidate",
      candidate,
    });
  };
  peerConnection.onconnectionstatechange = (evt) => {
    if (peerConnection.connectionState === "failed") {
      onConnectionFailed();
    }
  };
  peerConnection.onnegotiationneeded = async () => {
    await peerConnection.setLocalDescription(
      await peerConnection.createOffer()
    );
    send({
      type: "localDescription",
      receiverId: clientId,
      localDescription: peerConnection.localDescription,
    });
  };

  peerConnection.ontrack = (event) => {
    peerVideoRefs.current[index].srcObject = event.streams[0];
  };

  // the peer who joins the latest is responsible for
  // initializing the call.
  if (selfJoined > peerJoined) {
    localStream
      .getTracks()
      .forEach((track) => peerConnection.addTrack(track, localStream));
  }

  return {
    handleCandidateMessage: async (candidate: RTCIceCandidateInit) => {
      try {
        await peerConnection.addIceCandidate(candidate);
      } catch (error: any) {
        logger.info("Error add candidate", error, candidate);
      }
    },
    handleDescriptionMessage: async (
      description: RTCSessionDescriptionInit
    ) => {
      try {
        if (description.type === "offer") {
          await peerConnection.setRemoteDescription(description);
          localStream
            .getTracks()
            .forEach((track) => peerConnection.addTrack(track, localStream));

          const answer = await peerConnection.createAnswer();
          await peerConnection.setLocalDescription(answer);
          send({
            type: "localDescription",
            receiverId: clientId,
            localDescription: peerConnection.localDescription,
          });
        } else if (description.type === "answer") {
          await peerConnection.setRemoteDescription(description);
        }
      } catch (error: any) {
        logger.error(
          "unable to handle description message",
          error,
          description
        );
        onConnectionFailed();
      }
    },
    close: () => {
      peerConnection.close();
    },
  };
};
