import { useLocation } from "react-router-dom";
import { AlertTriangle } from "../Svg";

import styles from "./error.module.css";

const ErrorPage: React.VFC = () => {
  const search = useLocation().search;
  const errorCode = new URLSearchParams(search).get("errorCode")!;
  const returnUrl = new URLSearchParams(search).get("returnUrl")!;
  return (
    <div className={styles.errorContainer}>
      {errorCode === "permission-denied" && (
        <div className={styles.errorMessage}>
          <h3>
            <AlertTriangle />
            Nekad åtkomst till kameran och mikrofonen
          </h3>
          <div>
            Utan åtkomst till kameran och mikrofonen kan ett videmöte ej
            genomföras. Du behöver följa någon av nedanstående guider för att
            fortsätta:
          </div>
          <h5>Om du använder Google Chrome:</h5>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.google.com/chrome/answer/2693767?hl=sv&co=GENIE.Platform%3DDesktop"
          >
            Klicka här för att aktivera kameran och mikrofonen
          </a>
          <h5>Om du använder Safari:</h5>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.apple.com/sv-se/guide/safari/ibrwe2159f50/mac"
          >
            Klicka här för att aktivera kameran och mikrofonen
          </a>
          <h5>Om du använder Edge:</h5>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://support.microsoft.com/sv-se/windows/windows-mikrofon-och-sekretess-a83257bc-e990-d54a-d212-b5e41beba857"
          >
            Klicka här för att aktivera kameran och mikrofonen
          </a>
        </div>
      )}
      {errorCode === "websocket-closed" && (
        <div className={styles.errorMessage}>
          <h3>
            <AlertTriangle />
            Anslutningen bröts
          </h3>
          <div>
            Kopplingen bröts. Kontrollera din internetanslutning och ladda om
            sidan för att gå med i mötet igen.
            <a href={returnUrl}>Klicka här för att ladda om sidan</a>
          </div>
        </div>
      )}
      {errorCode === null && (
        <div className={styles.errorMessage}>
          <h3>
            <AlertTriangle />
            Ett okänt fel inträffade
          </h3>
          <div>
            Tyvärr kunde vi inte etablera en anslutning. Mötet kommer istället
            ske via telefon och du kommer bli uppringd.
          </div>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;
