import React from "react";

interface SVGProps {
  className?: string;
}

export const Logo: React.VFC<SVGProps> = (props) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 465.12 112.94" {...props}>
      <g>
        <g>
          <path
            d="M27.35,45.47l-4.22-8.31c-3.01-5.89-10.28-8.19-16.25-5.14l-0.24,0.12C0.68,35.2-1.71,42.44,1.3,48.33l12.02,23.51
                l20.79,41.1l13.72-27.12L27.38,45.47L27.35,45.47z"
          />
          <path
            d="M84.58,78.97L67.62,45.59l-0.07-0.13l0,0l-5.42-10.67c-3.01-5.89-10.23-8.22-16.11-5.21l-0.51,0.26
                c-5.89,3.01-8.22,10.23-5.21,16.11l11.3,22.09l22.71,44.9l13.72-27.12l-3.48-6.85L84.58,78.97z"
          />
          <path
            d="M106.72,43.91c-0.8-5.23-5.08-9.5-10.3-10.3c-8.39-1.28-15.52,5.84-14.23,14.24c0.8,5.23,5.08,9.5,10.31,10.3
                C100.89,59.43,108.01,52.3,106.72,43.91z"
          />
        </g>
        <path
          d="M137.2,73.2c0,19.86,6.28,36.44,12.77,39.73c-19.02,0-40.44-15.67-40.44-39.73c0-24.07,21.42-39.74,40.44-39.74
            C142.4,36.8,137.2,53.33,137.2,73.2z M194.2,73.2c0,24.06-21.42,39.73-40.44,39.73c6.49-3.3,12.77-19.87,12.77-39.73
            c0-19.87-5.2-36.39-12.77-39.74C172.78,33.46,194.2,49.13,194.2,73.2z"
        />
        <path
          d="M204.92,52.74l24.49-19.28v79.47h-24.49V52.74z M275.61,52.13l0,60.8h-24.49V46.86l-17.22,0.15l12.27-9.66
            c5.5-4.33,13.23-5.27,19.97-1.71C272.14,38.82,275.61,45.34,275.61,52.13z M321.81,52.21l0,60.72h-24.49V46.55l-16.66,0.02
            l11.71-9.22c5.53-4.36,13.33-5.29,20.07-1.64C318.39,38.94,321.81,45.45,321.81,52.21z"
        />
        <path
          d="M406.62,52.16l0,60.77h-24.49V46.87l-17.22,0.15l12.27-9.66c5.49-4.32,13.18-5.26,19.91-1.74
            C403.14,38.78,406.62,45.35,406.62,52.16z M448.34,99.73h16.78l-11.52,9.07c-2.18,1.72-4.67,3.07-7.38,3.67
            c-11.86,2.65-22.37-6.31-22.37-17.72V47.18h24.49V99.73z M449.23,28.92c0.06-0.49,0.15-0.98,0.15-1.49c0-0.89-0.11-1.75-0.29-2.59
            c-0.65-6.21-3.69-12.15-8.57-16.59c-4.89-4.45-13.93-9.45-29.12-8c-15.11,1.43-30.2,10.17-44.85,26c-2.15,2.33-4.72,4.89-7.61,7.63
            c-2.24,2.08-4.5,4.12-6.67,6.01l-16.33,12.86v60.19h24.49V37.57l0.38-0.36c3.28-3.07,6.13-5.91,8.47-8.44
            c14.01-15.13,28.3-23.48,42.47-24.82c13.86-1.3,21.95,3.11,26.28,7.06c1.79,1.63,3.27,3.49,4.45,5.51
            c-1.6-0.77-3.37-1.24-5.26-1.24c-6.72,0-12.16,5.45-12.16,12.16s5.45,12.16,12.16,12.16c6.2,0,11.26-4.66,12.01-10.66l0,0V28.92z"
        />
      </g>
    </svg>
  );
};

export const BankID: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Lager_1"
      x="0px"
      y="0px"
      viewBox="0 0 325.91199 308.79999"
      enable-background="new 0 0 595.3 841.9"
      width="325.91199"
      height="308.79999"
      {...props}>
      <g id="g117" transform="translate(-137.2,-262.6)">
        <path
          fill="#479cbe"
          d="m 241.9,423.6 13.2,-83.2 c -5.2,0 -14.1,0 -14.1,0 -6.6,0 -15.1,-3.7 -17.6,-10.5 -0.8,-2.3 -2.7,-10.2 8.2,-17.9 3.9,-2.7 6.4,-5.7 6.9,-8 0.5,-2.4 -0.1,-4.5 -1.8,-6.1 -2.4,-2.3 -7.1,-3.6 -13.1,-3.6 -10.1,0 -17.2,5.8 -17.9,10 -0.5,3.1 1.9,5.6 4,7.2 6.3,4.7 7.8,11.5 3.9,17.9 -4,6.6 -12.7,10.9 -22,11 0,0 -9.2,0 -14.4,0 -1.2,8.1 -20.8,132.3 -22.3,142.1 H 233 c 0.7,-4.4 4.3,-27.9 9.2,-58.9 z"
          id="path95"
        />
        <path
          fill="#00a5c3"
          d="M 346.5,267.6 H 267 l -10.6,67.3 h 13.5 c 7.4,0 14.4,-3.4 17.4,-8.3 1,-1.6 1.4,-3 1.4,-4.3 0,-2.8 -1.9,-4.9 -3.8,-6.3 -5.2,-3.9 -6.3,-8 -6.3,-10.9 0,-0.6 0,-1.1 0.1,-1.6 1.1,-7.1 10.7,-14.8 23.4,-14.8 7.6,0 13.4,1.8 16.9,5.1 3.1,2.9 4.3,7 3.4,11.3 -1.1,5.1 -6.2,9.3 -9.1,11.4 -7.7,5.4 -6.7,10.1 -6.2,11.5 1.6,4.2 7.7,6.9 12.4,6.9 H 340 c 0,0 0,0 0,0.1 28,0.2 43,13.1 38.3,43.1 -4.4,27.9 -25.8,39.9 -51.3,40.1 l -10.1,64.4 h 14.9 c 62.9,0 114.3,-40.4 124.4,-104.2 12.5,-79.2 -37.7,-110.8 -109.7,-110.8 z"
          id="path97"
        />
        <path
          fill="#235971"
          d="M 346.5,267.6 H 267 l -10.6,67.3 h 13.5 c 7.4,0 14.4,-3.4 17.4,-8.3 1,-1.6 1.4,-3 1.4,-4.3 0,-2.8 -1.9,-4.9 -3.8,-6.3 -5.2,-3.9 -6.3,-8 -6.3,-10.9 0,-0.6 0,-1.1 0.1,-1.6 1.1,-7.1 10.7,-14.8 23.4,-14.8 7.6,0 13.4,1.8 16.9,5.1 3.1,2.9 4.3,7 3.4,11.3 -1.1,5.1 -6.2,9.3 -9.1,11.4 -7.7,5.4 -6.7,10.1 -6.2,11.5 1.6,4.2 7.7,6.9 12.4,6.9 H 340 c 0,0 0,0 0,0.1 28,0.2 43,13.1 38.3,43.1 -4.4,27.9 -25.8,39.9 -51.3,40.1 l -10.1,64.4 h 14.9 c 62.9,0 114.3,-40.4 124.4,-104.2 12.5,-79.2 -37.7,-110.8 -109.7,-110.8 z"
          id="path99"
        />
        <g id="g109">
          <path
            fill="#235971"
            d="m 150.7,511.2 h 31.9 c 13.6,0 16.9,6.9 15.9,13.2 -0.8,5.1 -4.3,8.9 -10.3,11.4 7.6,2.9 10.6,7.4 9.5,14.5 -1.4,8.9 -9.1,15.5 -19.2,15.5 h -36.3 z m 21.1,22.6 c 6.2,0 9.1,-3.3 9.7,-7.2 0.6,-4.2 -1.3,-7.1 -7.5,-7.1 h -5.5 l -2.2,14.3 z m -3.4,23.6 c 6.4,0 10.1,-2.6 11,-7.9 0.7,-4.6 -1.9,-7.3 -8.1,-7.3 H 165 l -2.4,15.3 h 5.8 z"
            id="path101"
          />
          <path
            fill="#235971"
            d="m 242.4,566.2 c -8.3,0.6 -12.3,-0.3 -14.3,-3.9 -4.4,2.7 -9.3,4.1 -14.5,4.1 -9.4,0 -12.7,-4.9 -11.8,-10.3 0.4,-2.6 1.9,-5.1 4.3,-7.2 5.2,-4.5 18,-5.1 23,-8.5 0.4,-3.8 -1.1,-5.2 -5.8,-5.2 -5.5,0 -10.1,1.8 -18,7.2 l 1.9,-12.4 c 6.8,-4.9 13.4,-7.2 21,-7.2 9.7,0 18.3,4 16.7,14.6 l -1.9,12 c -0.7,4.2 -0.5,5.5 4.2,5.6 z M 228,547.4 c -4.4,2.8 -12.6,2.3 -13.5,8.1 -0.4,2.7 1.3,4.7 4,4.7 2.6,0 5.8,-1.1 8.4,-2.9 -0.2,-1 -0.1,-2 0.2,-3.9 z"
            id="path103"
          />
          <path
            fill="#235971"
            d="m 257.9,523.5 h 16.6 l -0.9,5.5 c 5.3,-4.5 9.3,-6.2 14.5,-6.2 9.3,0 13.6,5.7 12.1,15 l -4.3,27.9 h -16.6 l 3.6,-23.1 c 0.7,-4.2 -0.6,-6.2 -3.8,-6.2 -2.6,0 -5,1.4 -7.3,4.5 l -3.8,24.7 h -16.6 z"
            id="path105"
          />
          <path
            fill="#235971"
            d="m 313.1,511.2 h 16.6 l -4.2,26.8 15.9,-14.5 h 20.5 l -20.4,18 16.4,24.2 H 337 l -12.6,-19.5 h -0.2 l -3,19.5 h -16.6 z"
            id="path107"
          />
        </g>
        <g id="g115">
          <path
            fill="#479cbe"
            d="M 371.9,511.2 H 391 l -8.4,54.5 h -19.1 z"
            id="path111"
          />
          <path
            fill="#479cbe"
            d="m 400.3,511.2 h 27.3 c 21.1,0 27.2,15.3 25.2,28 -1.9,12.4 -11.7,26.5 -30.2,26.5 h -30.8 z m 17.7,41.5 c 9.3,0 14.4,-4.6 15.9,-14.3 1.1,-7.2 -1.1,-14.3 -11.4,-14.3 h -5.1 l -4.4,28.6 z"
            id="path113"
          />
        </g>
      </g>
    </svg>
  );
};

export const BankIDSmall: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 315.9 298.8"
      height="24"
      width="23"
      {...props}>
      <path d="M99.7 156l13.2-83.2H98.8c-6.6 0-15.1-3.7-17.6-10.5-.8-2.3-2.7-10.2 8.2-17.9 3.9-2.7 6.4-5.7 6.9-8 .5-2.4-.1-4.5-1.8-6.1-2.4-2.3-7.1-3.6-13.1-3.6-10.1 0-17.2 5.8-17.9 10-.5 3.1 1.9 5.6 4 7.2 6.3 4.7 7.8 11.5 3.9 17.9-4 6.6-12.7 10.9-22 11H35c-1.2 8.1-20.8 132.3-22.3 142.1h78.1c.7-4.4 4.3-27.9 9.2-58.9h-.3zM204.3 0h-79.5l-10.6 67.3h13.5c7.4 0 14.4-3.4 17.4-8.3 1-1.6 1.4-3 1.4-4.3 0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9 0-.6 0-1.1.1-1.6 1.1-7.1 10.7-14.8 23.4-14.8 7.6 0 13.4 1.8 16.9 5.1 3.1 2.9 4.3 7 3.4 11.3-1.1 5.1-6.2 9.3-9.1 11.4-7.7 5.4-6.7 10.1-6.2 11.5 1.6 4.2 7.7 6.9 12.4 6.9h20.5v.1c28 .2 43 13.1 38.3 43.1-4.4 27.9-25.8 39.9-51.3 40.1L174.7 215h14.9c62.9 0 114.3-40.4 124.4-104.2C326.5 31.6 276.3 0 204.3 0zM8.5 243.6h31.9c13.6 0 16.9 6.9 15.9 13.2-.8 5.1-4.3 8.9-10.3 11.4 7.6 2.9 10.6 7.4 9.5 14.5-1.4 8.9-9.1 15.5-19.2 15.5H0l8.5-54.6zm21.1 22.6c6.2 0 9.1-3.3 9.7-7.2.6-4.2-1.3-7.1-7.5-7.1h-5.5l-2.2 14.3h5.5zm-3.4 23.6c6.4 0 10.1-2.6 11-7.9.7-4.6-1.9-7.3-8.1-7.3h-6.3l-2.4 15.3 5.8-.1zM100.2 298.6c-8.3.6-12.3-.3-14.3-3.9-4.4 2.7-9.3 4.1-14.5 4.1-9.4 0-12.7-4.9-11.8-10.3.4-2.6 1.9-5.1 4.3-7.2 5.2-4.5 18-5.1 23-8.5.4-3.8-1.1-5.2-5.8-5.2-5.5 0-10.1 1.8-18 7.2l1.9-12.4c6.8-4.9 13.4-7.2 21-7.2 9.7 0 18.3 4 16.7 14.6l-1.9 12c-.7 4.2-.5 5.5 4.2 5.6l-4.8 11.2zm-14.4-18.8c-4.4 2.8-12.6 2.3-13.5 8.1-.4 2.7 1.3 4.7 4 4.7 2.6 0 5.8-1.1 8.4-2.9-.2-1-.1-2 .2-3.9l.9-6zM115.7 255.9h16.6l-.9 5.5c5.3-4.5 9.3-6.2 14.5-6.2 9.3 0 13.6 5.7 12.1 15l-4.3 27.9h-16.6l3.6-23.1c.7-4.2-.6-6.2-3.8-6.2-2.6 0-5 1.4-7.3 4.5l-3.8 24.7h-16.6l6.5-42.1zM170.9 243.6h16.6l-4.2 26.8 15.9-14.5h20.5l-20.4 18 16.4 24.2h-20.9l-12.6-19.5h-.2l-3 19.5h-16.6l8.5-54.5z"></path>
      <g>
        <path d="M229.7 243.6h19.1l-8.4 54.5h-19.1l8.4-54.5zM258.1 243.6h27.3c21.1 0 27.2 15.3 25.2 28-1.9 12.4-11.7 26.5-30.2 26.5h-30.8l8.5-54.5zm17.7 41.5c9.3 0 14.4-4.6 15.9-14.3 1.1-7.2-1.1-14.3-11.4-14.3h-5.1l-4.4 28.6h5z"></path>
      </g>
    </svg>
  );
};

export const AlertTriangle: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20">
      <path
        fill="#ff5d00"
        d="M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z"
      />
    </svg>
  );
};

export const Microphone: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      {...props}>
      <g>
        <g>
          <g>
            <path
              d="M426.667,213.333c-11.797,0-21.333,9.557-21.333,21.333V256c0,82.347-67.008,149.333-149.333,149.333
				S106.667,338.347,106.667,256v-21.333c0-11.776-9.536-21.333-21.333-21.333c-11.797,0-21.333,9.557-21.333,21.333V256
				c0,98.645,74.816,180.096,170.667,190.741v22.592H192c-11.797,0-21.333,9.557-21.333,21.333S180.203,512,192,512h128
				c11.797,0,21.333-9.557,21.333-21.333s-9.536-21.333-21.333-21.333h-42.667v-22.592C373.184,436.096,448,354.645,448,256v-21.333
				C448,222.891,438.464,213.333,426.667,213.333z"
            />
            <path
              d="M256,384c70.592,0,128-57.408,128-128V128C384,57.408,326.592,0,256,0S128,57.408,128,128v128
				C128,326.592,185.408,384,256,384z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Camera: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7z" />
    </svg>
  );
};

export const Speaker: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 490 490"
      {...props}>
      <g>
        <g>
          <g>
            <rect x="108.1" y="149.7" width="76.2" height="190.2" />
            <polygon points="204.2,355.1 381.9,490 381.9,0 204.2,134.9 			" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const HangUp: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="752pt"
      height="752pt"
      version="1.1"
      viewBox="0 0 752 752"
      {...props}>
      <defs>
        <clipPath id="a">
          <path d="m139.21 203h473.58v214h-473.58z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="m590.54 293.78c-120.73-120.7-316.44-120.69-437.07 0.015625-29.504 29.5-4.0547 112.34-4.0547 112.34 2.2109 7.2656 10.109 11.82 17.523 10.094l113.29-26.355c7.4102-1.6953 13.465-9.3438 13.465-16.945l0.023438-60.543c0-7.6016 6.2227-13.824 13.824-13.824h136.09c7.6211 0 13.844 6.2227 13.844 13.824l-0.015625 57.016c0.015625 7.6016 6.1602 14.895 13.633 16.191l125.39 21.613c7.4922 1.3164 14.34-3.8164 15.223-11.367 0 0.007812 8.3398-72.559-21.168-102.05z" />
      </g>
      <path d="m404.96 352.55h-57.473v72.098h-43.113l71.652 124.1 71.68-124.1h-42.746z" />
    </svg>
  );
};
