import {useEffect, useState} from "react";

interface VolumeIndicatorProps {
  stream: MediaStream;
}

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

export const VolumeIndicator: React.VFC<VolumeIndicatorProps> = ({stream}) => {
  const [currentVolume, setCurrentVolume] = useState<number>(0);
  useEffect(() => {
    if (!AudioContext) return;
    let currentAnimationFrame = 0;
    const audioContext = new AudioContext();
    const mediaStreamAudioSourceNode =
      audioContext.createMediaStreamSource(stream);
    const analyserNode = audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Float32Array(analyserNode.fftSize);
    const onFrame = () => {
      if (!analyserNode.getFloatTimeDomainData) {
        return;
      }
      analyserNode.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (let i = 0; i < pcmData.length; i++) {
        const amplitude = pcmData[i];
        sumSquares += amplitude * amplitude;
      }
      setCurrentVolume(Math.sqrt(sumSquares / pcmData.length));
      currentAnimationFrame = window.requestAnimationFrame(onFrame);
    };
    currentAnimationFrame = window.requestAnimationFrame(onFrame);
    return () => {
      cancelAnimationFrame(currentAnimationFrame);
    };
  }, [stream]);

  return (
    <div
      style={{
        backgroundColor: "#9f59b3",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        position: "relative",
      }}>
      <div
        style={{
          width: `5px`,
          height: `${currentVolume * 10 + 5}px`,
          borderRadius: "2px",
          backgroundColor: "#FFF",
          position: "absolute",
          top: "50%",
          left: "7px",
          transform: "translate(-50%, -50%)",
        }}></div>
      <div
        style={{
          width: `5px`,
          height: `${currentVolume * 30 + 5}px`,
          borderRadius: "2px",
          backgroundColor: "#FFF",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}></div>
      <div
        style={{
          width: `5px`,
          height: `${currentVolume * 10 + 5}px`,
          borderRadius: "2px",
          backgroundColor: "#FFF",
          position: "absolute",
          top: "50%",
          left: "23px",
          transform: "translate(-50%, -50%)",
        }}></div>
    </div>
  );
};
