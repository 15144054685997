import {useCookies} from "react-cookie";
import {TurnAuthorization} from "../types";

const useTurnAuth = (): TurnAuthorization | undefined => {
  const [cookies] = useCookies(["turn_authorization"]);
  try {
    return JSON.parse(atob(cookies.turn_authorization)) as TurnAuthorization;
  } catch (error) {
    console.error(error);
    return;
  }
};

export default useTurnAuth;
