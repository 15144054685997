import {Booking} from "./types";

export const ValidateAuth = async () => {
  try {
    const response = await request(
      `${process.env.REACT_APP_API_URL}/auth/validate`
    );
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const GetMyBookings = async (): Promise<Booking[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/bookings`
  );
  return response.json();
};
export const GetMyBookingFromId = async (id: string): Promise<Booking> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/bookings/${id}`
  );
  if (!response.ok) {
    throw new Error(`invalid status code ${response.status}`);
  }
  return response.json();
};

const request = async (
  url: string,
  method: string = "GET",
  body: string | null = null
) => {
  const response = await fetch(url, {
    credentials: "include",
    method,
    body,
  });
  return response;
};
