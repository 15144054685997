export function must<T>(f: () => T | undefined): T {
  const v = f();
  if (!v) throw new Error("must failed for " + f);
  return v;
}

export function diff<T>(
  prev: T[],
  next: T[],
  equality: (a: T, b: T) => boolean
): [T[], number[]] {
  const add: T[] = [];
  const drop: number[] = [];
  next.forEach((member) => {
    let found = false;
    prev.forEach((peer) => {
      if (equality(peer, member)) {
        found = true;
      }
    });
    if (!found) {
      add.push(member);
    }
  });
  prev.forEach((member, i) => {
    let found = false;
    next.forEach((peer) => {
      if (equality(peer, member)) {
        found = true;
      }
    });
    if (!found) {
      drop.push(i);
    }
  });
  return [add, drop];
}
