import React from "react";
import {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ValidateAuth} from "./api";

import Call from "./pages/Call";
import Home from "./pages/Home";
import ErrorPage from "./pages/Error";

const loginUrl =
  `${process.env.REACT_APP_APP_URL}/login?redirectURL=` +
  encodeURIComponent(window.location.href);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    ValidateAuth().then(setIsAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const minute = 60 * 1000;
      const interval = setInterval(() => {
        ValidateAuth();
      }, 5 * minute);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  return (
    <Router>
      <Switch>
        <ProtectedRoute
          //isAuthenticated={isAuthenticated}
          exact
          path="/"
          component={Home}></ProtectedRoute>
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/error"
          component={ErrorPage}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/:meetingId"
          component={Call}
        />
      </Switch>
    </Router>
  );
};

const ProtectedRoute = ({component, isAuthenticated, ...rest}: any) => {
  const routeComponent = (props: any) => {
    if (isAuthenticated === true) {
      return React.createElement(component, props);
    } else if (isAuthenticated === false) {
      window.location.assign(loginUrl);
    }
    return <div></div>;
  };
  return <Route {...rest} render={routeComponent} />;
};

export default App;
