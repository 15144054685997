import Rollbar from "rollbar";
import {Logger} from "../types";

const rollbar = new Rollbar({
  accessToken: "48c0218b5d2043829c1cc5005c226c4a",
  environment:
    process.env.REACT_APP_ROLLBAR_ENV === "development"
      ? "local"
      : "production",
  captureUncaught: true,
  captureUnhandledRejections: true,
});

const useLogger = (): Logger => {
  return rollbar;
};

export default useLogger;
