import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import useLogger from "../hooks/useLogger";
import useTurnAuth from "../hooks/useTurnAuth";
import { isMobile } from "react-device-detect";
import { must } from "../lib/util";
import {
  CallHandler,
  createCallHandler,
  Peer,
  VideoFatalError,
} from "../lib/callHandler";
import React from "react";
import styles from "./call.module.css";
import clsx from "clsx";
import { useClaimsMust } from "../hooks/useClaims";
import { GetMyBookingFromId } from "../api";
import { Booking } from "../types";
import { Camera, HangUp, Microphone } from "../Svg";
import { VolumeIndicator } from "../components/VolumeIndicator";

interface Params {
  meetingId: string;
}

const Call: React.VFC = () => {
  const logger = useLogger();
  const turnAuthorization = must(useTurnAuth);
  const claims = useClaimsMust();

  const { meetingId } = useParams<Params>();

  const [peers, setPeers] = useState<Peer[]>([]);
  const peerVideoRefs = useRef<HTMLVideoElement[]>([]);
  const localVideoRef = useRef<HTMLVideoElement>(null);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [joined, setJoined] = useState<boolean>(false);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[] | null>(null);
  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string | null>(
    null
  );
  const [videoInputDeviceId, setVideoInputDeviceId] = useState<string | null>(
    null
  );

  const [localStream, setLocalStream] = useState<MediaStream | null>(null);

  const onPeerListUpdated = (peers: Peer[]) => {
    setPeers(peers.map((p) => p));
  };

  const onFatalErrorOccured = (error: VideoFatalError) => {
    window.location.href = `/error?errorCode=${error}&returnUrl=/${meetingId}`;
  };

  useEffect(() => {
    if (!joined) return;
    if (!localStream) return;
    let callHandler: CallHandler;
    callHandler = createCallHandler({
      meetingId,
      turnAuthorization,
      logger,
      peerVideoRefs,
      localStream,
      onPeerListUpdated,
      onFatalErrorOccured,
    });

    return () => {
      callHandler.cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStream, joined]);

  const leaveCall = () => {
    setJoined(false);
  };

  const configureLocalStream = async () => {
    try {
      const constraints = {
        audio: {
          deviceId: audioInputDeviceId
            ? { exact: audioInputDeviceId }
            : undefined,
        },
        video: {
          deviceId: videoInputDeviceId
            ? { exact: videoInputDeviceId }
            : undefined,
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      localVideoRef.current!.srcObject = stream;
      setLocalStream(stream);
    } catch (error: any) {
      logger.error("unable to get user media", error);
      window.location.href = `/error?errorCode=permission-denied&returnUrl=/${meetingId}`;
      return;
    }

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setDevices(devices);
    } catch (error: any) {
      logger.error("unable to get user devices", error);
    }
  };

  useEffect(() => {
    configureLocalStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioInputDeviceId, videoInputDeviceId]);

  useEffect(() => {
    GetMyBookingFromId(meetingId)
      .then(setBooking)
      .catch((error) =>
        logger.error("Unable to load booking " + meetingId, error)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAudioEnabled = () => {
    if (!localStream) return;
    const audioTrack = localStream.getAudioTracks().find(() => true);
    if (!audioTrack) return;
    audioTrack.enabled = !audioTrack.enabled;
    setAudioEnabled(audioTrack.enabled);
  };

  const toggleVideoEnabled = () => {
    if (!localStream) return;
    const videoTrack = localStream.getVideoTracks().find(() => true);
    if (!videoTrack) return;
    videoTrack.enabled = !videoTrack.enabled;
    setVideoEnabled(videoTrack.enabled);
  };

  return (
    <>
      <div
        className={clsx(styles.topPadding, {
          [styles.desktop]: !isMobile,
        })}
      ></div>

      <div
        className={clsx(styles.localVideoContainer, {
          [styles.desktop]: !isMobile,
          [styles.connected]: joined,
          [styles.waiting]: !joined,
        })}
      >
        {localStream && (
          <div
            className={clsx(styles.volumeIndicatorContainer, {
              [styles.desktop]: !isMobile,
              [styles.connected]: joined,
              [styles.waiting]: !joined,
            })}
          >
            <VolumeIndicator stream={localStream} />
          </div>
        )}
        <video
          autoPlay
          muted
          playsInline
          ref={localVideoRef}
          className={clsx(styles.localVideo, {
            [styles.connected]: joined,
            [styles.waiting]: !joined,
            [styles.desktop]: !isMobile,
          })}
        />
        {!joined && (
          <div>
            <button
              onClick={() => setJoined(true)}
              className={clsx(styles.startButton, {
                [styles.desktop]: !isMobile,
              })}
            >
              Gå med i möte{" "}
            </button>
            <DeviceEditor
              audioDeviceChanged={setAudioInputDeviceId}
              videoDeviceChanged={setVideoInputDeviceId}
              localStream={localStream}
              devices={devices}
            />
          </div>
        )}
      </div>
      {peers.length === 0 && joined && (
        <>
          {(claims.role === "admin" || claims.role === "medical") && (
            <div className={styles.peerInfo}>
              Du är ensam i mötet, väntar på att patienten skall ansluta
            </div>
          )}
          {claims.role === "customer" && (
            <div className={styles.peerInfo}>
              Du är nu uppkopplad i mötet.
              {booking && (
                <div style={{ paddingTop: "10px" }}>
                  {booking.bookedPerson.role} {booking.bookedPerson.firstName}{" "}
                  {booking.bookedPerson.lastName} kommer att dyka upp inom kort,
                  vänligen vänta.
                </div>
              )}
            </div>
          )}
        </>
      )}
      <div
        className={clsx(styles.peersContainer, {
          [styles.multiPeer]: peers.length > 1,
        })}
      >
        {peers.map((pv, index) => (
          <div key={pv.clientId} className={clsx(styles.remoteVideoContainer)}>
            <div>
              <video
                className={clsx(styles.remoteVideo, {
                  [styles.connected]: joined,
                  [styles.desktop]: !isMobile,
                })}
                autoPlay
                playsInline
                ref={(element) => (peerVideoRefs.current[index] = element!)}
              />
            </div>
            <div className={styles.peerName}>{pv.name} </div>
          </div>
        ))}
      </div>

      {joined && (
        <div
          className={clsx(styles.controlsContainer, {
            [styles.desktop]: !isMobile,
          })}
        >
          <div
            className={clsx(styles.controlButton, styles.hangUpButton)}
            onClick={leaveCall}
          >
            <HangUp />
          </div>
          <div
            className={clsx(styles.controlButton, {
              [styles.disabled]: !videoEnabled,
            })}
            onClick={toggleVideoEnabled}
          >
            <Camera />
          </div>
          <div
            className={clsx(styles.controlButton, {
              [styles.disabled]: !audioEnabled,
            })}
            onClick={toggleAudioEnabled}
          >
            <Microphone />
          </div>
        </div>
      )}
    </>
  );
};

interface DeviceEditorProps {
  localStream: MediaStream | null;
  devices: MediaDeviceInfo[] | null;
  audioDeviceChanged: (deviceId: string) => void;
  videoDeviceChanged: (deviceId: string) => void;
}

const DeviceEditor: React.VFC<DeviceEditorProps> = ({
  localStream,
  devices,
  audioDeviceChanged,
  videoDeviceChanged,
}) => {
  const [isAudioEditorOpen, setIsAudioEditorOpen] = useState<boolean>(false);
  const [isVideoEditorOpen, setIsVideoEditorOpen] = useState<boolean>(false);
  const firstOrUndefined = (tracks: MediaStreamTrack[] | undefined) => {
    if (!tracks) return undefined;
    if (tracks.length === 0) return undefined;
    return tracks[0];
  };
  const audioTrack = firstOrUndefined(localStream?.getAudioTracks());
  const videoTrack = firstOrUndefined(localStream?.getVideoTracks());
  const audioInputDevices = devices?.filter(
    (device) => device.kind === "audioinput"
  );
  const videoInputDevices = devices?.filter(
    (device) => device.kind === "videoinput"
  );
  const changeAudioTrack = (deviceId: string) => {
    setIsAudioEditorOpen(false);
    audioDeviceChanged(deviceId);
  };
  const changeVideoTrack = (deviceId: string) => {
    setIsAudioEditorOpen(false);
    videoDeviceChanged(deviceId);
  };

  return (
    <div
      className={clsx(styles.deviceInfo, {
        [styles.disconnected]: !localStream,
      })}
    >
      <div className={styles.device}>
        <Microphone />
        {!isAudioEditorOpen && (
          <>
            {audioTrack && <span>{audioTrack.label}</span>}
            <button
              className={styles.textButton}
              onClick={() => setIsAudioEditorOpen(true)}
            >
              Ändra
            </button>
          </>
        )}
        {isAudioEditorOpen && (
          <select
            onChange={(evt) => changeAudioTrack(evt.target.value)}
            defaultValue={
              audioInputDevices?.find((mdf) => mdf.label === audioTrack?.label)
                ?.deviceId
            }
          >
            {audioInputDevices?.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={styles.device}>
        <Camera />
        {!isVideoEditorOpen && (
          <>
            {videoTrack && <span>{videoTrack.label}</span>}
            {localStream?.getVideoTracks().length === 0 && (
              <span>Du har ingen mikrofon</span>
            )}
            <button
              className={styles.textButton}
              onClick={() => setIsVideoEditorOpen(true)}
            >
              Ändra
            </button>
          </>
        )}
        {isVideoEditorOpen && (
          <select
            onChange={(evt) => changeVideoTrack(evt.target.value)}
            defaultValue={
              videoInputDevices?.find((mdf) => mdf.label === videoTrack?.label)
                ?.deviceId
            }
          >
            {videoInputDevices?.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default Call;
